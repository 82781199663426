.head {
    margin: 0px 0px;
    margin-bottom: 80px;
}

.loading {
    margin-top: 100px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.recents h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0px;
    padding: 8px 15px;
    gap: 65px;
}

.transactions_list {
    margin: 0px 20px;
    height: 350px;
    overflow: scroll;
}

.recents {
    background-color: #F1F1F1;
}