.main_body_stats {
    margin: 0px 18px;
    display: flex;
    background-color: #F5FFF1;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    gap: 12px;
}

.main_body_stats_card {
    padding: 16px 24px;
    box-sizing: border-box;
    gap: 12px;
    width: 132px;
    height: 132px;
    background: #F1F1F1;
    box-shadow: 0px 6px 12px rgba(14, 31, 53, 0.05), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 1px 4px rgba(14, 31, 53, 0.12);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 4px 10px 4px;
}

.main_body_stats_card svg {
    margin: 0px;
    margin-right: 10px;
}

.main_body_stats_card h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #141414;
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 5px;
}

.main_body_stats_card p {
    font-weight: 450;
    font-size: 14px;
    margin: 0px;
    line-height: 24px;
    color: #141414;
}

.main_body_stats_card div {
    display: flex;
    /* justify-content: space-between; */
}

@media only screen and (max-width: 360px) {
    .main_body_stats {
        padding: 10px;
        gap: 8px;
    }
}

@media only screen and (max-width: 320px) {
    .main_body_stats {
        padding: 0px;
        gap: 8px;
    }
}