.imageWrapper {
    width: 100%;
}

.imageWrapper img {
    width: 100%;
}

.card {
    margin: 50px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card svg {
    margin: 0px;
    margin-bottom: 20px;
}

.card div h4 {
    font-weight: 700;
    font-size: 24px;
    margin: 0px;
    margin-bottom: 14px;
    color: #3C3E3F;
}

.card div p {
    margin: 0px;
    font-weight: 450;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 14px;
    color: #3C3E3F;
}

.card div p:nth-child(2) {
    margin-bottom: 0px;
    line-height: 30px;
}

.card div p span:nth-child(1) {
    font-weight: 700;
}

.card div p span:nth-child(2) {
    color: #008069;
    font-style: italic;
}

.card div p:nth-child(4) {
    font-size: 16px;
    color: #263238;
}

.card div span {
    text-decoration: none;
    font-weight: 700;
    color: #008069;
    margin: 0px;
    margin-left: 10px;
}

.buttons {
    margin: 0px;
}

.buttons a {
    margin: 0px;
    margin-bottom: 12px;
}