* {
    box-sizing: border-box;
}

.App {
    display: none;
}

.image-item {
    margin: 10px 0 10px 10px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.menu {
    z-index: 100000;
    position: fixed;
    /* height: 100vh; */
    width: 100%;
    background-color: rgba(255, 255, 255, 0.606);
}

.body {
    display: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media only screen and (max-width: 920px) {
    .App {
        display: block;
    }

    .body {
        display: flex;
        flex-direction: column;
    }
}