@media only screen and (max-width: 920px) {
    .login {
        display: flex;
        flex-direction: column;
    }

    .login__hero .login__hero__description {
        margin: 0px 25px;
    }

    .login__hero .login__hero__description p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.24px;
    }

    .login__hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-top: 50px;
    }

    .form {
        margin: 0px 24px;
    }

    .header p {
        margin-top: 13px;
        font-weight: 700;
        font-size: 24px;
    }

    .form__inner {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }

    .form__passwordInput {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .form__inner {
        width: 95%;
    }

    .form__inner .form__passwordInput {
        width: 108%;
    }

    .form__inner .form__passwordInput input {
        border: 1px solid #bababa;
        border-radius: 12px;
        width: 100%;
    }

    .form__inner .form__passwordInput div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form__inner .form__passwordInput svg {
        font-size: 17px;
        cursor: pointer;
        right: 35px;
        position: absolute;
    }

    .form__inner input {
        width: 100%;
        font-size: 16px;
        border: 1px solid #BABABA;
        border-radius: 12px;
        padding: 13px 12px;
    }

    .form__inner input:focus {
        outline: none;
        border: 1px solid #0080694D;
        background-color: #FBFFFE;
    }

    .form__inner label {
        color: #3C3E3F;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.1rem;
    }

    .actions {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .actions button {
        cursor: pointer;
        color: white;
        background-color: #008069;
        border: 1px solid #008069;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        border-radius: 8px;
        width: 100%;
        height: 44px;
        transition: all 0.2s ease-in-out;
        padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
    }

    .actions button:hover {
        background-color: #008069;
        border-color: #008069;
        transition: all 0.2s ease-in-out;
    }

    .actions button:disabled {
        cursor: no-drop;
        background-color: #0080694D;
        border: 1px solid #0080694D;
        transition: all 0.2s ease-in-out;
    }

    .login__options p {
        color: #3C3E3F;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        margin: 10px 0px;
        font-weight: 400;
        justify-content: flex-end;
    }

    .login__options span a {
        text-decoration: none;
        font-weight: 700;
        color: #008069;
        margin: 0px;
        margin-left: 10px;
    }

    .form__inner .invalid {
        color: #FD4D1E;
    }

    .form__inner .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }

    .form__inner .form__passwordInput .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }

    .button {
        margin-top: 34px;
    }
}