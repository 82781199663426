.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.75);
}

.modal2 {
    position: relative;
    margin: auto;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
}

.modal {
    position: relative;
    margin: auto;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
}

.modal_subscription {
    position: relative;
    margin: auto;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
}

.header {
    background: white;
    text-align: center;
    color: black;
    margin: 0;
    margin-top: 20px;
}

.svg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.content {
    padding: 0rem 1rem;
    margin: 20px 0px;
    text-align: center;
    color: #263238;
}

.actions {
    padding: 1rem;
    display: flex;
    align-items: center;
}

.actions div {
    width: 100%;
    margin: 0px 15px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__options {
    display: flex;
    flex-direction: column;
    margin: 15px 4px;
    align-items: flex-end;
}

.login__options .login__options_inner p {
    color: #3C3E3F;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
}

.login__options .login__options_inner a {
    text-decoration: none;
    font-weight: 700;
    color: #008069;
    font-size: 14px;
    display: flex;
}

.form {
    margin: 0px 20px;
}

.login__options_inner {
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: flex-start;
}

header h3 {
    margin: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

.content p {
    margin: 0px;
    color: #737272;
    text-align: center;
}

.subscriptionOption {
    margin: 0px 15px;
}

.subscriptionOption div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 8px;
    gap: 12px;
    border-radius: 6px;
    margin: 20px 0px;
}

.subscriptionOption div p {
    margin: 0px;
}

.selectInput {
    margin: 0px 14px;
}

.actions2 {
    margin: 30px 14px;
}

.help {
    width: 50px;
    height: 50px;
    border: 1px #D7FBDB solid;
    border-radius: 50%;
    -webkit-animation: rotation 1s ease-in-out infinite;
    -moz-animation: rotation 1s ease-in-out infinite;
    animation: rotation 1s ease-in-out infinite;
    margin: 30px auto;
}

.help:after {
    width: 20px;
    height: 20px;
    background-color: #008069;
    border-radius: 100%;
    position: absolute;
    content: "";
}

.image {
    display: flex;
    width: 80%;
    margin: 20px auto;
}

.imageWrapper {
    display: flex;
    height: auto;
    width: 100%;
}

.imageWrapper img {
    width: 100%;
    display: flex;
    height: auto;
}

.form_inner_input {
    display: flex;
    justify-content: space-between;
}

.form_inner_input input {
    width: 95%;
}


.form {
    margin: 0px 24px;
}

.header p {
    margin-top: 13px;
    font-weight: 700;
    font-size: 24px;
}

.form__inner {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.form__passwordInput {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form__inner {
    width: 95%;
}

.form__inner input {
    width: 100%;
}

.form__inner .form__passwordInput {
    width: 108%;
}

.form__inner .form__passwordInput input {
    border: 1px solid #bababa;
    border-radius: 12px;
    width: 100%;
}

.form__inner .form__passwordInput div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__inner .form__passwordInput svg {
    font-size: 17px;
    cursor: pointer;
    right: 35px;
    position: absolute;
}

.form__inner input {
    width: 100%;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 12px;
    padding: 13px 12px;
}

.form__inner input:focus {
    outline: none;
    border: 1px solid #0080694D;
    background-color: #FBFFFE;
}

.form__inner label {
    color: #3C3E3F;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.1rem;
}


.forgotPassword {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.forgotPassword span a {
    text-decoration: none;
    color: #008069;
    display: flex;
    font-weight: 400;
    cursor: pointer;
    margin: 0px;
    font-size: 14px;
}

.login__options p {
    color: #3C3E3F;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    margin: 10px 0px;
    font-weight: 400;
    justify-content: flex-end;
}

.login__options span a {
    text-decoration: none;
    font-weight: 700;
    color: #088457;
    margin: 0px;
    margin-left: 10px;
}

.form__inner .invalid {
    color: #FD4D1E;
}

.form__inner .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

.form__inner .form__passwordInput .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

.form__inner {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.form__passwordInput {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form__inner {
    width: 100%;
}

.form__inner select {
    width: 100%;
}

.form__inner .form__passwordInput {
    width: 100%;
}

.form__inner select {
    width: 100%;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 4px;
    padding: 13px 12px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
}

.form__inner select:focus {
    outline: none;
    border: 1px solid #0080694D;
    background-color: #FBFFFE;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotation {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}