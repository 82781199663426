.wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 12px;
    background-color: #D7FBDB;
}

.wallet_ballance {
    margin: 10px 0px;
}

.wallet_ballance:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.wallet_ballance span {
    display: flex;
    align-items: center;
}

.wallet_ballance span p:nth-child(2) {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #263238;
}

.wallet_ballance h3 {
    color: #008068;
    font-size: 23px;
    margin: 0px;
    margin-bottom: 12px;
}

.wallet_ballance p {
    margin: 0px;
}

.wallet_ballance div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.wallet_ballance div h3 {
    margin: 0px;
    color: #3C3E3F;
    margin-left: 8px;
}