.head {
    margin: 30px 10px 50px 10px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin: 40px 10px 20px 10px;
    background-color: white;
    position: fixed;
    top: 0;
    width: 92%;
}

.loading {
    margin-top: 100px;
}

.main_hero_inner {
    display: flex;
    flex-direction: column;
}

.transactions {
    margin: 5px 20px;
}

.transactions h4 {
    margin: 12px 0px;
}

.transactions_list {
    margin-top: 120px;
    overflow: auto;
    height: 68vh;
}

.pagination {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
}

.pagination div {
    display: flex;
    padding: 5px;
    justify-content: center;
    width: 30px;
}

.workShop_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.workShop_head p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #008069;
}

.add {
    background: #D7FBDB;
    padding: 8px;
}

.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50% auto;
}

.empty h3,
.empty p {
    margin: 17px 0px;
}

.empty p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 24px;
    text-align: center;
    color: #999999;
}

.empty h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 1rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.overlay {
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.609);
}

.modal {
    display: flex;
    position: relative;
    height: 25em;
    width: 21.875em;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
}

.form {
    width: 100%;
    padding: 0px 15px;
}

.modal h1 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.form__inner label {
    color: #3C3E3F;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.1rem;
}

.form__inner select {
    width: 100%;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 6px;
    padding: 13px 12px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: 1rem;
}

.form__inner select:focus {
    outline: none;
}