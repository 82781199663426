.workShop_list {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    background: #FFFFFF;
    padding: 10px 15px 8px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    height: 110px;
}

.workShop_list2 {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    background: #FFFFFF;
    padding: 10px 15px 8px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.workShop_list2 .pDetails h5 {
    margin: 0px;
}

.workShop_list2 .contact h5,
.workShop_list2 .contact p {
    margin: 0px;
}

.workShop_list2 .contact {
    margin: 12px 0px;
}


.contact_lines {
    align-items: center;
    display: flex;
    padding: 10px 15px;
    margin-top: 10px;
}

.contact_lines svg {
    margin-right: 20px;
}

.pDetails .pDetails_name {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
}

.pDetails p {
    margin: 0px;
    color: #3C3E3F;
}

.pDetails h5 {
    margin: 0px;
    margin-top: 15px;
    color: #3C3E3F;
}


.contact p {
    margin: 0px;
    margin-bottom: 10px;
    color: #3C3E3F;
}

.contact h5 {
    margin: 0px;
    margin-top: 15px;
    color: #3C3E3F;
}

.contact .contact_id {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    color: #3C3E3F;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact span svg {
    margin-left: 12px;
}