.form__inner {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.form__inner__input select {
    width: 100%;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 6px;
    padding: 13px 12px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
}

.form__inner label {
    color: #3C3E3F;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.1rem;
}


.form__inner__input select:focus {
    outline: none;
    border: 1px solid #0080694D;
    background-color: #FBFFFE;
}