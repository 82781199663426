@media only screen and (max-width: 920px) {
    .form__inner {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }

    .form__emailInput {
        align-items: center;
        display: flex;
        background-color: #E7E7E7;
        border-radius: 8px;
        gap: 6px;
        width: 100%;
        justify-content: space-between;
    }

    .form__inner input {
        width: 100%;
        font-size: 16px;
        border: 0.2px solid #E7E7E7;
        border-radius: 6px;
        background-color: #E7E7E7;
        padding: 13px 12px;
    }

    .form__inner .form__emailInput div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form__inner .form__emailInput div svg {
        font-size: 17px;
        cursor: pointer;
        margin-left: 8px;
    }

    .form__inner input:focus {
        outline: none;
        background-color: #E7E7E7;
    }

    .form__inner label {
        color: #3C3E3F;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.1rem;
    }

    .form__inner .invalid {
        color: #FD4D1E;
    }

    .form__inner .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }

    .form__inner .form__emailInput .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }


    .form__inner2 {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }

    .form__inner2 .form__emailInput {
        align-items: center;
        display: flex;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        gap: 6px;
        width: 100%;
        justify-content: space-between;
    }

    .form__inner2 input {
        width: 100%;
        font-size: 16px;
        border: 0.2px solid #FFFFFF;
        border-radius: 6px;
        background-color: #FFFFFF;
        padding: 13px 12px;
    }

    .form__inner2 .form__emailInput div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form__inner2 .form__emailInput div svg {
        font-size: 17px;
        cursor: pointer;
        margin-left: 8px;
    }

    .form__inner2 input:focus {
        outline: none;
        background-color: #FFFFFF;
    }

    .form__inner2 label {
        color: #3C3E3F;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.1rem;
    }

    .form__inner2 .invalid {
        color: #FD4D1E;
    }

    .form__inner2 .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }

    .form__inner2 .form__emailInput .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }
}