.walletCover {
    margin: 0px 15px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 80px;
}

.heading_text p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #2C3A4B;
}

.card {
    background: linear-gradient(186.9deg, #008069 26.79%, #35D853 113.67%);
    border-radius: 15px;
    margin: 20px 0px;
    height: 210px;
}

.card_inner {
    display: flex;
    justify-content: space-between;
    margin: 0px 19px;
    padding: 5px 0px;
}

.card_inner_balance {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 22px;
    color: #FFFFFF;
}


.card_inner_balance p {
    margin: 0px;
    margin-bottom: 12px;
}

.card_inner_balance h2 {
    font-weight: 700;
    margin: 0px;
    font-size: 32px;
    line-height: 40px;
}

.card_inner_logo {
    margin-top: 22px;
    justify-content: flex-end;
    display: flex;
    width: 60%;
}

.card_inner:nth-child(2) {
    align-items: flex-end;
}

.card_inner_logo svg {
    margin-bottom: 12px;
}

.card_inner_logo svg:nth-child(2) {
    margin-left: -12px;
}

.options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.options_body {
    background: #D7FBDB;
    border-radius: 4px;
    padding: 2px 15px;
    gap: 8px;
    width: 100%;
    margin: 0px;
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.options_body:nth-child(1) {
    margin-right: 12px;
}

.options_body p {
    margin: 0px;
    padding: 8px 15px;
    gap: 16px;
    color: #008069;
}

.loading {
    margin-top: 40px;
}

.transactions_options {
    display: flex;
    justify-content: space-between;
    background: #F1F1F1;
    padding: 4px 15px;
    gap: 15px;
    align-items: center;
    color: #8EA396;
}

.transactionList_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
}

.transactionList_inner div p {
    margin: 0px;
}

.transactionList_inner div:nth-child(2) {
    /* background-color: red; */
    margin: 0px 0px;
    width: 45%;
}

.transactionList_inner div h3 {
    margin: 0px;
}

.transactionList_icon {
    background: #E2FBD7;
    border-radius: 12px;
    padding: 16px;
    gap: 10px;
}

.transactionList_inner div h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2C3A4B;
}

.transactionList_inner div p {
    font-weight: 450;
    font-size: 12px;
    line-height: 28px;
    color: #8EA396;
}

.transactionList_amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactionList_amount h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #2C3A4B;
    margin-right: 32px;
}

.calender_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.calender_filter div {
    flex: 1;
}

.filterButton {
    width: 100%;
}

.filterButton div svg {
    margin: 0px 9px;
}

.transactionList_wrapper {
    overflow: scroll;
    height: 200px;
}

.transactions_options a {
    color: #2C3A4B;
    margin: 0px;
}

.active {
    color: #008069 !important;
}