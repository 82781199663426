.body {
    display: flex;
    width: 100%;
}

.system_codes2 {
    display: flex;
    margin: auto;
    width: 100%;
    height: 208px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin: 0rem 0rem;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.08);
}

.err_header {
    display: flex;
    padding: 8px 15px;
    width: 100%;
    height: 44px;
    justify-content: space-between;
    align-items: center;
    background: var(--color-light-red-100, #FFDFD7);
}

.system_codes_details2 {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.err_header div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.details {
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
}

.details h4 {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 100% */
}

.details p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
    /* 142.857% */
}

.err_header div:nth-child(2) p:nth-child(2) {
    color: #FD4D1E;
}

.err_header div:nth-child(1) p:nth-child(2) {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.button {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.button div {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--color-green-02, #D7FBDB);
}

.button p {
    color: var(--color-green-104, #008069);
    text-align: center;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}