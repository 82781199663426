@media only screen and (max-width: 920px) {
    .form__inner {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }

    .form__inner__input {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .form__inner input {
        width: 100%;
        font-size: 16px;
        border: 1px solid #BABABA;
        border-radius: 6px;
        padding: 13px 12px;
    }

    .form__inner input:focus {
        outline: none;
        border: 1px solid #0080694D;
        background-color: #FBFFFE;
    }

    .form__inner label {
        color: #3C3E3F;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.1rem;
    }

    .form__inner .invalid {
        color: #FD4D1E;
    }

    .form__inner .invalidInput {
        background-color: #FFFBFB;
        border: 1px solid #FD4D1E;
        color: #FD4D1E;
    }
}