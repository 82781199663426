.head {
    margin-bottom: 80px;
    /* margin: 0px 15px; */
}

.main_hero {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.vehicleDetails {
    margin: 0px 15px;
}

.carWrapper {
    margin: 0px 15px;
}

.subscription__wrapper {
    margin: 0px 15px;
    margin-right: 15px;
    display: flex;
    width: 100%;
    margin: 0px 15px;
}

.subscription__wrapper img {
    width: 100%;
    height: 100%;
}

.vehicleDetails {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.recentTransactions {
    padding: 5px 0px;
    margin-top: 5px;
}


.recentTransactions p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    margin: 0px;
    align-items: center;
    text-align: center;
    color: #263238;
}

.recentTransactionsTitle {
    background-color: #D7FBDB;
    padding: 16px 0px;
    gap: 8px;
}

.recentTransactions h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    margin-left: 15px;
    color: #263238;
}

.transactions_list {
    height: 350px;
    overflow: scroll;
    margin: 0px 15px;
}

.options {
    margin: 0px 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.options_body {
    background: #D7FBDB;
    border-radius: 4px;
    padding: 0px 15px;
    gap: 8px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.options_body p {
    margin: 0px;
    padding: 8px 15px;
}

.loading {
    margin-top: 100px;
}

.options_body:nth-child(2) {
    background: #008069;
    border-radius: 4px;
    padding: 0px 15px;
    gap: 8px;
    width: 100%;
    margin: 0px;
    margin-left: 15px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    color: #FFFFFF;
}