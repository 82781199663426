.main_hero_header_inner {
    display: flex;
    margin-bottom: 0px;
    padding: 0px;
    align-items: center;
    margin: 5px 15px;
    justify-content: space-between;
}

.main_hero_displayImage {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main_hero_displayImage div {
    margin-right: 10px;
}

.main_hero_displayImage_wrapper {
    width: 60%;
    object-fit: contain;
}

.main_hero_displayImage_wrapper img {
    width: 100%;
}

.main_hero_profileInfo {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}

.main_hero_profileInfo:nth-child(2) {
    width: 100%;
}

.main_hero_profileInfo h4 {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #263238;
}

.main_hero_profile {
    display: flex;
}

.main_hero_profileInfo p {
    font-style: normal;
    margin: 0px;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: #8EA396;
}

.main_hero_profile .main_hero_profileInfo:nth-child(1) {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}