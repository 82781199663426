.navLinks {
    display: flex;
    justify-content: space-between;
}

.navLinks a {
    color: #8EA396;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
}

.navLinks .active {
    color: #008069;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}