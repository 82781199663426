.history {
    margin-top: 18px;
}

.list {
    display: flex;
    margin: 15px 0px;
    background: #FFFFFF;
    gap: 12px;
    padding: 16px 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    justify-content: space-between;
    align-items: center;
}

.list div {
    margin: 10px 5px;
}

.list div p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #8EA396;
}

.list div h5 {
    font-weight: 700;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 18px;
}

.list div .title {
    color: #EF5DA8;
}

.form div .input {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
}

/* .form {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    padding: 7px 8px;
    gap: 6px;
} */

.list_info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.calender_filter {
    display: flex;
    justify-content: space-between;
}

.filterButton {
    width: 100%;
}

.filterButton div svg {
    margin: 0px 9px;
}

.history_header {
    position: fixed;
    background-color: white;
    width: 90%;
}

.transactions_list {
    margin-top: 130px;
}