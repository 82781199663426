.head {
    margin: 0px 2px;
    margin-bottom: 80px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.vend_status {
    display: flex;
    margin: 0px 15px;
    justify-content: space-between;
}

.vend_diagnosis_form {
    margin: 0px 15px;
}

.vend_diagnosis_form h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #4A4A4A;
}

.vend_diagnosis_form_inner p {
    font-weight: 450;
    font-size: 16px;
    line-height: 26px;
    color: #3C3E3F;
    margin: 0px;
    margin-bottom: 12px;
}

.vend_status_card {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    width: 60%;
    gap: 8px;
    background: #F3F3F3;
    border-radius: 8px;
}

.vend_status_card:nth-child(1) {
    margin-right: 12px;
}

.vend_status_card:nth-child(1) {
    justify-content: space-between;
}

.vend_status_card div p {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.vend_status_card div p:nth-child(1) {
    color: #008069;
    font-weight: 700;
}

.button {
    margin: 60px 0px;
}

.modalInner {
    display: flex;
    align-items: center;
    padding: 12px 4px;
    margin: 0px 8px;
    height: 44px;
    background: #F1F1F1;
}

.modalInner svg {
    margin: 0px 10px 0px 5px;
}

.modalInner p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #263238;
    padding: 12px 16px;
    gap: 12px;
}

.check_box {
    display: flex;
    align-items: center;
    height: 40px
}

.check_box p {
    font-size: 12px;
}

@media only screen and (max-width: 360px) {
    .vend_status_card div p {
        font-size: 12px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 320px) {
    .vend_status_card div p {
        font-size: 10px;
        line-height: 20px;
    }
}