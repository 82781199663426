.head {
    margin: 0px 2px;
    margin-bottom: 80px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.subscription__wrapper {
    display: flex;
    width: 100%;
}

.subscription__wrapper img {
    width: 100%;
    height: 100%;
}

.vehicleInfo {
    margin: 0px 15px;
}

.button {
    margin: 30px 15px;
}

.system_codes_head {
    gap: 10px;
    border-radius: 4px;
}

.system_codes h3 {
    font-weight: 500;
    padding: 12px 5px;
    margin: 0px;
    font-size: 16px;
    background-color: #E7E7E7;
    line-height: 20px;
    color: #263238;
}

.loading {
    margin-top: 100px;
}

.system_codes p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* Color/Dark grey 100 */

    color: #263238;

}