.carStatus {
    display: flex;
    flex-direction: column;
    background-color: #E7E7E7;
    margin: 30px 0px;
    padding: 10px 10px;
}

.carStatus__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}

.carStatus__inner2 {
    display: flex;
    margin-bottom: 12px;
    height: 100%;
    background-color: white;
    justify-content: space-between;
}

.carStatus__inner2 p {
    display: flex;
    align-items: center;
    margin: 0px;
}

.carStatus__inner2 p span {
    margin-right: 4px;
}

.carStatus__inner h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    color: #263238;
}

.carStatus__inner2__title {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    flex: 1;
    max-width: 70%;
    display: flex;
    background-color: #FFFFFF;
    color: #263238;
}

.carStatus__inner2__title__head {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    flex: 1;
    max-width: 60%;
    flex-direction: column;
    display: flex;
    background-color: #ffffff;
    color: #263238;
}

.carStatus__inner2__status__head {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: 40%;
    height: 100%;
    align-items: center;
    background-color: #FFF5CC;
    padding: 10px 20px;
    position: relative;
    color: #FF9900;
}

.carStatus__inner2__status {
    font-weight: 500;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    max-width: 30%;
    display: flex;
    flex: 1;
    align-items: center;
    background-color: #FFF5CC;
    padding: 10px 15px;
    text-align: center;
    color: #FF9900;
}