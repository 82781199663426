.vehicleDetails {
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    border-radius: 8px;
}

.vehicleDetails div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.vehicleDetails div h4 {
    margin: 0px;
    font-size: 20px;
}

.vehicleDetails div p {
    margin: 0px;
    font-size: 12px;
}

.vehicleDetails_2 div {
    padding: 10px 0px;
    background: #F7F7F7;
    margin-top: 5px;
}

.vehicleDetails_2 p {
    gap: 10;
    margin: 10px 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}