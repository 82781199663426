@media only screen and (max-width: 920px) {
    .navBar {
        background-color: #FFFFFF;
        align-items: center;
        width: 100%;
        display: flex;
        position: fixed;
        z-index: 10000;
    }

    .navBar__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        width: 100%;
    }

    .navBar__hamburger {
        cursor: pointer;
    }

    .navBar__hamburger div {
        background-color: #3C3E3F;
        height: 2px;
        margin-top: 4px;
        width: 16px;
    }

    .navBar__title {
        display: flex;
        margin-left: 12px;
        align-items: center;
        text-align: center;
    }

    .navBar__title p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .navBar__notifications {
        display: flex;
        justify-content: space-between;
    }

    .navBar__notifications svg {
        margin-left: 5px;
    }

}